import React from "react";
import TechStack from "../../components/TechStack"
const TechStackPage = props => {
  
  const {
    data: {
      site: {
        siteMetadata: { facebook }
      }
    },
    language
  } = props;
    return (

<div>
  <TechStack language = {language} facebook={facebook}/>
    </div>
  );
};
export default TechStackPage;

//eslint-disable-next-line no-undef
export const query = graphql`
  query ContactQuery3 {
    site {
      siteMetadata {
        facebook {
          appId
        }
      }
    }
  }
`;

