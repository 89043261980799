import React from "react";
import {
  Button,
  Container,
  Divider,
  Grid,
  Header,
  Icon,
  Image,
  List,
  Menu,
  Segment,
  Visibility
} from "semantic-ui-react";
import "./TechStack.css";
import Spring from "../../../static/img/tech_icons/spring.png";
import Java from "../../../static/img/tech_icons/java.png";
import Iac from "../../../static/img/tech_icons/iac.png";
import Android from "../../../static/img/tech_icons/android.png";
import Dynamodb from "../../../static/img/tech_icons/dynamodb.png";
import Node from "../../../static/img/tech_icons/nodejs.png";
import Css3 from "../../../static/img/tech_icons/css3.png";
import Aws from "../../../static/img/tech_icons/aws.png";
import Git from "../../../static/img/tech_icons/git.png";
import Serverless from "../../../static/img/tech_icons/serverless.png";
import Html5 from "../../../static/img/tech_icons/html5.png";
import Javascript from "../../../static/img/tech_icons/javascript.png";
import ReactLogo from "../../../static/img/tech_icons/react.png";
import Swagger from "../../../static/img/tech_icons/swagger.png";
import Sql from "../../../static/img/tech_icons/sql.png";
import {FormattedMessage} from 'react-intl';
import Seo from "../../components/Seo";

const TechStack = props => {
  
  const {
     facebook 
      ,
    language
  } = props;
    return (
    <div>
      <Segment
        style={{ padding: "8em 0em", textAlign: "center" }}
        vertical
        inverted
      >
        <Container>
          <Divider
            as="h2"
            className="header"
            horizontal
            inverted
            style={{ margin: "3em 0em", textTransform: "uppercase" }}
          >
          <FormattedMessage
                    id="techStack"/>
          </Divider>

          <Grid>
            {/* Shows up on Mobile view only */}
            <Grid.Row columns={5} only="mobile">

              <Grid.Column className="overlayEffect">
                <img src={Java} />
                <p className="title">
                <FormattedMessage id="Java"/>
                </p>
                <div className="overlay" />
              </Grid.Column>
              <Grid.Column className="overlayEffect">
                <img src={Javascript} />
                <p className="title">EcmaScript</p>
                <div className="overlay" />
              </Grid.Column>
              <Grid.Column className="overlayEffect">
                <img src={Spring} />
                <p className="title">
                <FormattedMessage id="Spring"/>
                </p>
                <div className="overlay" />
              </Grid.Column>
              <Grid.Column className="overlayEffect">
              <img src={Aws} />
                <p className="title">
                <FormattedMessage id="Aws"/>
                </p>
                <div className="overlay" />
              </Grid.Column>
              <Grid.Column className="overlayEffect">
              <img src={Node} />
                <p className="title">
                <FormattedMessage id="Node"/>
                </p>
                <div className="overlay" />
              </Grid.Column>
              <Grid.Column className="overlayEffect">
              <img src={Serverless} />
                <p className="title">
                <FormattedMessage id="Serverless"/>
                </p>
                <div className="overlay" />
              </Grid.Column>
              <Grid.Column className="overlayEffect">
                <img src={ReactLogo} />
                <p className="title">React</p>
                <div className="overlay" />
              </Grid.Column>
              <Grid.Column className="overlayEffect">
              <img src={Android} />
                <p className="title">
                <FormattedMessage id="Android"/>
                </p>
                <div className="overlay" />
              </Grid.Column>
              <Grid.Column className="overlayEffect">
                <img src={Sql} />
                <p className="title">
                <FormattedMessage id="Sql"/>
                </p>
                <div className="overlay" />
              </Grid.Column>
              <Grid.Column className="overlayEffect">
                <img src={Dynamodb} />
                <p className="title">
                <FormattedMessage id="Nosql"/>
                </p>
                <div className="overlay" />
              </Grid.Column>
              <Grid.Column className="overlayEffect">
                <img src={Html5} />
                <p className="title">Html5</p>
                <div className="overlay" />
              </Grid.Column>
              <Grid.Column className="overlayEffect">
                <img src={Css3} />
                <p className="title">CSS3</p>
                <div className="overlay" />
              </Grid.Column>
              <Grid.Column className="overlayEffect">
              <img src={Iac} />
                <p className="title">
                <FormattedMessage id="Iac"/>
                </p>
                <div className="overlay" />
              </Grid.Column>

              <Grid.Column className="overlayEffect">
              <img src={Swagger} />
                <p className="title">
                <FormattedMessage id="Swagger"/>
                </p>
                <div className="overlay" />
              </Grid.Column>
              <Grid.Column className="overlayEffect">
                <img src={Git} />
                <p className="title">Git</p>
                <div className="overlay" />
              </Grid.Column>
              

            </Grid.Row>
            {/*  Computer/Tablet section used unless user is on a mobile device
            <Grid.Row columns={6} className="column" only="computer tablet">
              <Grid.Column className="overlayEffect">
                <img src={Spring} />
                <p className="title">Spring</p>
                <div className="overlay" />
              </Grid.Column>
              <Grid.Column className="overlayEffect">
                <img src={Java} />
                <p className="title">  
                 <FormattedMessage id="Java"/>
                </p>
                <div className="overlay" />
              </Grid.Column>
              <Grid.Column className="overlayEffect">
                <img src={Bootstrap} />
                <p className="title">Bootstrap</p>
                <div className="overlay" />
              </Grid.Column>
              <Grid.Column className="overlayEffect">
                <img src={CSharp} />
                <p className="title">C#</p>
                <div className="overlay" />
              </Grid.Column>
              <Grid.Column className="overlayEffect">
                <img src={Css3} />
                <p className="title">CSS3</p>
                <div className="overlay" />
              </Grid.Column>
              <Grid.Column className="overlayEffect">
                <img src={Firebase} />
                <p className="title">Firebase</p>
                <div className="overlay" />
              </Grid.Column>
              <Grid.Column className="overlayEffect">
                <img src={Git} />
                <p className="title">Git</p>
                <div className="overlay" />
              </Grid.Column>
              <Grid.Column className="overlayEffect">
                <img src={GruntLogo} />
                <p className="title">Grunt</p>
                <div className="overlay" />
              </Grid.Column>
              <Grid.Column className="overlayEffect">
                <img src={Html5} />
                <p className="title">Html5</p>
                <div className="overlay" />
              </Grid.Column>
              <Grid.Column className="overlayEffect">
                <img src={Javascript} />
                <p className="title">Javascript</p>
                <div className="overlay" />
              </Grid.Column>
              <Grid.Column className="overlayEffect">
                <img src={Jquery} />
                <p className="title">JQuery</p>
                <div className="overlay" />
              </Grid.Column>
              <Grid.Column className="overlayEffect">
                <img src={Npm} />
                <p className="title">Npm</p>
                <div className="overlay" />
              </Grid.Column>
              <Grid.Column className="overlayEffect">
                <img src={Octocat} />
                <p className="title">Github</p>
                <div className="overlay" />
              </Grid.Column>
              <Grid.Column className="overlayEffect">
                <img src={ReactLogo} />
                <p className="title">React</p>
                <div className="overlay" />
              </Grid.Column>
              <Grid.Column className="overlayEffect">
                <img src={Sass} />
                <p className="title">Sass</p>
                <div className="overlay" />
              </Grid.Column>
              <Grid.Column className="overlayEffect">
                <img src={Sql} />
                <p className="title">Sql</p>
                <div className="overlay" />
              </Grid.Column>
              <Grid.Column className="overlayEffect">
                <img src={VisualStudio} />
                <p className="title">Visual Studio</p>
                <div className="overlay" />
              </Grid.Column>
              <Grid.Column className="overlayEffect">
                <img src={Webpack} />
                <p className="title">Webpack</p>
                <div className="overlay" />
              </Grid.Column>
            </Grid.Row> */}
          </Grid>
        </Container>
      </Segment>
      <Seo facebook={facebook} language={language}/>

    </div>
  );
};

export default TechStack;



